import * as React from 'react'
import * as css from './NextArticles.module.css'
import PostPreview from 'components/common/PostPreview'
import clsx from 'clsx'

const NextArticles = ({ posts }) => (
  <section className={clsx(css.root, 'section-padding')}>
    <h2 className="h4">Read This Next...</h2>

    <ul className={css.list}>
      {posts?.map((post, key) => (
        <li key={key}>
          <PostPreview post={post} />
        </li>
      ))}
    </ul>
  </section>
)

export default NextArticles
