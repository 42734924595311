import * as React from 'react'
import * as css from './PostActions.module.css'
import { Link } from 'gatsby'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { FaFacebookF, FaTwitter, FaPinterestP } from 'react-icons/fa'
import newtab from 'utils/newtab'
import clsx from 'clsx'

const PostActions = ({ post, location }) => {
  const pinterestParams = new URLSearchParams()
  pinterestParams.set('url', location.href)
  post.featuredImage && pinterestParams.set('media', post.featuredImage.asset.url)

  return (
    <aside className={css.root}>
      <div className={clsx(css.list, 'sticky')}>
        <Link className={css.back} to="/" title="Back to Blog Homepage">
          <HiArrowNarrowLeft />
        </Link>

        <Link title="Share on Facebook" to={`http://www.facebook.com/sharer.php?u=${ location.href }`} {...newtab}>
          <FaFacebookF />
        </Link>
        <Link title="Share on Twitter" to={`http://twitter.com/share?text=${ post.title }&url=${ location.href }`} {...newtab}>
          <FaTwitter />
        </Link>
        <Link title="Pin on Pinterest" to={`http://pinterest.com/pin/create/button/?${ pinterestParams.toString() }`} {...newtab}>
          <FaPinterestP />
        </Link>
      </div>
    </aside>
  )
}

export default PostActions
