import * as React from 'react'
import * as css from './Sidebar.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'components/Img'
import { MdChevronRight } from 'react-icons/md'

const Sidebar = () => {
	const { sidebar } = useStaticQuery(query)

	return (
		<aside className={css.root}>
			<h2 className="h4 text-center">{sidebar.title}</h2>

			<div className={css.products}>
				{sidebar.products?.map((product, key) => (
					<a href={product.url}>
						<figure>
							<Img
								className={css.img}
								image={product.image}
								alt={product.title}
							/>
						</figure>
						<div className="with-icon">
							{product.title} <MdChevronRight />
						</div>
					</a>
				))}
			</div>

			<hr />

			<div className={css.testimonials}>
				{sidebar.testimonials?.map((testimonial, key) => (
					<div>
						<blockquote key={key}>
							<div className={css.stars}>★★★★★</div>
							<p>{testimonial.quote}</p>
							<cite>{testimonial.author}</cite>
						</blockquote>
					</div>
				))}
			</div>
		</aside>
	)
}

export default Sidebar

const query = graphql`query Sidebar {
	sidebar: sanitySidebar {
		title
		products {
			title
			url
			image { asset { gatsbyImageData(placeholder: NONE, width: 100, aspectRatio: 1) } }
		}
		testimonials {
			quote
			author
		}
	}
}`
