import * as React from 'react'
import * as css from './post.module.css'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Img from 'components/Img'
import PostActions from 'components/post/PostActions'
import DateCategory from 'components/common/DateCategory'
import Sidebar from 'components/post/Sidebar'
import SanityBlock from 'components/common/SanityBlock'
import NextArticles from 'components/post/NextArticles'
import clsx from 'clsx'

const PostTemplate = ({ data, location }) => {
  const {
    sanityBlogPost: post,
    nextArticles
  } = data

  return (
    <DefaultLayout {...post.seo} className="container side-padding">
      <section className={clsx(css.root, 'section-padding')}>
        <PostActions post={post} location={location} />

        <article className={css.article}>
          <header className={css.heading}>
            <h1 className="h1">{post.title}</h1>
            <DateCategory className={css.info} post={post} />
          </header>

          {post.featuredImage && (
            <div className={css.img}>
              <Img image={post.featuredImage} alt={post.title} />
            </div>
          )}
          <SanityBlock className={css.body} body={post._rawBody} />
        </article>

        <Sidebar />
      </section>

      <NextArticles posts={nextArticles.nodes} />
    </DefaultLayout>
  )
}

export default PostTemplate

export const query = graphql`query Post($id: String) {
  sanityBlogPost(id: {eq: $id}) {
    title
    publishDate
    formattedPublishDate: publishDate(formatString: "MMMM D, yyyy")
    category { title }
    _rawBody
    body { children { text } }
    featuredImage { asset { gatsbyImageData(placeholder: NONE) } }
    seo {
      title
      description
    }
  }
  nextArticles: allSanityBlogPost(
    filter: {id: {ne: $id}},
    limit: 3,
    sort: {fields: publishDate, order: DESC}
  ) {
    nodes {
      title
      body { children { text } }
      publishDate
      formattedPublishDate: publishDate(formatString: "MMMM D, yyyy")
      featuredImage {
        asset {
          gatsbyImageData(width: 550, aspectRatio: 1.5)
          url
        }
      }
      category { title }
      seo {
        description
        slug { current }
      }
    }
  }
}`
